import React, { Component } from "react";
import * as THREE from 'three';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import ply from "./apple_target_object_cloud.ply";
import block from "./target_object_cloud94.ply";

const canvas = document.getElementById("canvas");
class Cube extends Component {
    
  componentDidMount() {
    
    const container = document.getElementById( 'canvas2' );

    let renderer, scene, camera, stats;
    let mesh;
    
    if (this.first) return; this.first = true;
    init();
    function init() {

        renderer = new THREE.WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( 400, 400, false);

        container.appendChild( renderer.domElement );

        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 100 );
        camera.position.z = 0.0;
        camera.position.y = -0.0;
        //camera.position.y = -5;
        //camera.rotation.z = 3.1415;
        //camera.rotateOnAxis(new THREE.Vector3(1.0, 0.0, 0.0), 3.1415/2.0)
        scene.add( new THREE.AmbientLight( 0x443333 ) );

        const controls = new OrbitControls( camera, renderer.domElement );
        controls.minDistance = 1;
        controls.maxDistance = 5;

        scene.add( new THREE.AmbientLight( 0x443333 ) );

        const dirLight1 = new THREE.DirectionalLight( 0xffddcc, 1 );
        dirLight1.position.set( 1, 0.75, 0.5 );
        scene.add( dirLight1 );

        const dirLight2 = new THREE.DirectionalLight( 0xccccff, 1 );
        dirLight2.position.set( - 1, 0.75, - 0.5 );
        scene.add( dirLight2 );

        var loader = new PLYLoader();
        var group = new THREE.Object3D();
        loader.load(block, function (geometry) {
            const material = new THREE.PointsMaterial( {size: 0.02} );
            material.vertexColors = true;
            const mesh = new THREE.Points( geometry, material );
            
            mesh.rotation.z = Math.PI/2;
            mesh.rotation.y = Math.PI/3;
            mesh.rotation.x = Math.PI/3;

            scene.add( mesh );
            //group = new THREE.Points(geometry, material);
            //group.sortParticles = true;
            //scene.add(group);
        });

        animate();

    }

    function animate() {
        requestAnimationFrame( animate );
        renderer.render( scene, camera );
    }
   
    // === THREE.JS EXAMPLE CODE END ===
  }
  render() {
    return (
      <div align='center'>
        <br />
        <h2>Point Cloud</h2>
        <br />
        <div style={{width:400,height:400}} id="canvas2"></div>
      </div>
    )
  }
}
export default Cube;