import React, { Component } from 'react'
//import styled from 'styled-components'
import video from "./NavigateToSink.MOV";


class Navigation extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        
        return (

	    <div align='center'>
            <br />
      		<h2>Navigation</h2>
            <br />
            <i>
            Currently using text base commands.
            <br />Video 1:
            <i>Go To the Sink</i>
            <br />
            Robot scans the room to locate the target object, which in this case is the sink.<br />
            Once located, he uses the ROS Navigation package to generate and follow a path.
            <br />
            <br />
            </i>
            <video controls autoPlay muted > 
                <source src={video} type="video/mp4" />
            </video>
        </div>
    )
    }
}

export default Navigation
