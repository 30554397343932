import React, { Component } from 'react'
//import styled from 'styled-components'

class PanTilt extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        
        return (

	    <div align='center'>
            <br />
      		<h2>Pan Tilt</h2>
            <br />
            Using a URDF and the transform system, you can transform your camera's point cloud to a common frame.
            <br />
            
        </div>
    )
    }
}

export default PanTilt
