
import './App.css';
import Cube from './Pages/Cube'
import Navigation from './Pages/Navigation'
import {BrowserRouter as Router, Link, Routes, Route} from 'react-router-dom';
import ObjectDetection from './Pages/ObjectDetection'
import InAction from './Pages/InAction'
import PointCloud from './Pages/PointCloud'
import PanTilt from './Pages/PanTilt'
import React, { useState, useEffect } from "react";

function App() {
  const [activeclassname, setclassname] = useState('active');
  const [navItems, setNavItems] = useState([
  {
      id: 1,
      linkTo: "/",
      title: "Home",
      isActive: 'active',
  },
  {
      id: 2,
      linkTo: "/objectdetection",
      title: "Object Detection",
      isActive: ''
  },
  /*{
    id: 3,
    linkTo: "/cube",
    title: "Point Cloud",
    isActive: '',
  },*/
  {
    id: 4,
    linkTo: "/navigation",
    title: "Navigation",
    isActive: '',
  },
  {
    id: 5,
    linkTo: "/blockdetection",
    title: "Block Detection",
    isActive: '',
  }

  ]);
  
  const handleUpdateNav = (idx) => {
    setNavItems(prevNavItems => {
      for (var i=0;i< prevNavItems.length;i++){
        if  (i == idx)
          prevNavItems[i].isActive='active';
        else prevNavItems[i].isActive='';
      }
      return [...prevNavItems];
    }
    /*setItems(
      items.map((friend) =>
          // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
          friend.id === idx
              ? { ...friend, isActive: 'active' }
              : { ...friend, isActive : 'no' }
      )
      */
  );
  }
  const closeMenu = (e) => {
    var x = document.getElementById("myTopnav");
    x.className = "topnav";
  };
  const handleClick = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  };
  return (
    <>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    <Router>
    <div id="myTopnav" className="topnav">
      {navItems.map((n, index) => (
        <Link key={index} to={n.linkTo} >
            <a key={n.id} id={n.id} className={n.isActive}
            onClick={() => {handleUpdateNav(index);closeMenu();}}
            href="javascript:void(0);">{n.title}</a>
        </Link>
      ))}
      
      <a href="javascript:void(0);" className="icon" onClick={handleClick}>
        <i className="fa fa-bars"></i>
      </a>
      </div>
      <Routes>
        <Route path="/cube" element={<Cube/>} />
        <Route path="/pantilt" element={<PanTilt/>} />
        <Route path="/navigation" element={<Navigation/>} />
        <Route path="/" element={<InAction/>} />
        <Route path="/objectdetection" element={<ObjectDetection/>} />
        <Route path="/blockdetection" element={<PointCloud/>} />
      </Routes>
    </Router>
  <br></br>
  <div className="App">
  </div>
  <hr />
  <footer> <small>&copy; Copyright 2022, V Bonte, Expert in DDS<br/></small>
  <small><i>If you use any content, please reference this website.</i></small><br/>
  </footer> 
  </>
);
}

export default App;
