import React, { Component } from 'react'
import styled from 'styled-components'
import video from "./IMG_1711.MOV";

const Wrapper = styled.div`
    padding: 0 40px 40px 40px;
`

const Update = styled.div`
    color: #ef9b0f;
    cursor: pointer;
`

const Delete = styled.div`
    color: #ff0000;
    cursor: pointer;
`
class BlockDetection extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div align='center'>                        
                <br />
                <h2>Block Detection</h2>
                <br />

                <div class="row">
                    <div class="column">
                    
                    <h4>    
                        Using Open3d we can detect blocks on a flat surface.  
                        </h4>
                        <br />

                        You can view the code in my github repository here:
                        <a target="_blank" href="https://github.com/valeri-robotics-repo/Open3d_detectblocks">
                        https://github.com/valeri-robotics-repo/Open3d_detectblocks
                        </a>
                        <br />
                        <br />
                        An example of implementation in ROS2 is here:
                        <a target="_blank" href="https://github.com/valeri-robotics-repo/blockdetection_ros2">https://github.com/valeri-robotics-repo/blockdetection_ros2</a>

                        
                        <br />
                        <br />

                        In this video my robot is stacking blocks using the above library.
                        Notice the end stack is a little messy.  
                        This is due to the inaccuracies introduced by the arm and the camera.
                    </div>
                    <div class="column">
                        <video controls muted>
                            <source src={video} type="video/mp4" />
                        </video>

                    </div>
                </div>
            </div>
        )
    }
}

export default BlockDetection
