import React, { Component } from 'react'
import styled from 'styled-components'
import video from "./objectdetections081421.mov";

const Wrapper = styled.div`
    padding: 0 40px 40px 40px;
`

const Update = styled.div`
    color: #ef9b0f;
    cursor: pointer;
`

const Delete = styled.div`
    color: #ff0000;
    cursor: pointer;
`

class ObjectDetection extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        
        return (
            <div align='center'>
            <br />
            <h2>Object Detection</h2>
            <br />
                <i>This is what the robot sees; using a custom trained Tensorflow Mask Rcnn and novel cnn for color detection.  Video playback slowed to 1 fps.</i>
                        <br /> <br />
                    <video controls autoPlay  muted>
                            <source src={video} type="video/mp4" />
                        </video>
            </div>  
        )
    }
}

export default ObjectDetection
