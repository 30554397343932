import React, { Component } from 'react'
import video from "./AppleOrangeBowl.MOV";

import cuponplatevideo from "./CupOnPlate.MOV";

import styled from 'styled-components'

const Title = styled.h1.attrs({
    className: 'h1',
})``

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    margin: 0 30px;
`

const Label = styled.label`
    margin: 5px;
`

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
    margin: 15px 15px 15px 5px;
`

const CancelButton = styled.a.attrs({
    className: `btn btn-danger`,
})`
    margin: 15px 15px 15px 5px;
`

class InAction extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        
        return (

        <div align='center'>
            <br />
            <h2>Edward the Robot</h2>
            Custom robot creation using servos for 6 dof robotic arm and
            2 dof pan/tilt system, wheeled base, and Intel Realsense 435 camera. <br />
            Build on ROS/ROS2.

            <h3>Basic Commands</h3>
            <br />	
            <br />

            Currently using text base commands.

            <br />Video 1:
            <i>Put the apple in the bowl
                    ...
            Put the orange in the bowl</i>
                <br /> <br />
                <div class="video">
			<video controls autoPlay  muted>
       		 		<source src={video} type="video/mp4" />
                            </video>
</div>
            <br /><br />
            <br />Video 2:
            <i>Put the cup on the plate</i><br /><br />
            <div class="video">
            <video controls muted >
                    <source src={cuponplatevideo} type="video/mp4" />
            </video>
            </div>
            </div>

        )
    }
}

export default InAction
